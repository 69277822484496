import React from 'react';
import './Monado.css';

function Monado() {
  return (
    <div className="Monado">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="494.000000pt" height="620.000000pt" viewBox="0 0 494.000000 620.000000" preserveAspectRatio="xMidYMid meet">
      <metadata>
        Created by potrace 1.13, written by Peter Selinger 2001-2015
      </metadata>
      <g transform="translate(0.000000,620.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path d="M4358 6182 c-23 -5 -23 -15 1 -36 16 -15 32 -17 101 -11 228 17 289
                2 328 -76 52 -105 -8 -220 -126 -242 -50 -10 -126 26 -154 71 -28 45 -30 125
                -5 163 15 22 15 27 3 32 -15 6 -125 -1 -138 -9 -4 -3 -126 -145 -270 -317
                -144 -172 -339 -402 -433 -512 -381 -446 -383 -448 -377 -484 3 -23 -1 -38
                -14 -51 -11 -11 -24 -25 -29 -32 -6 -7 -59 -70 -118 -140 -59 -70 -107 -132
                -107 -136 0 -5 45 -12 100 -15 72 -4 124 -14 183 -35 84 -29 220 -109 211
                -124 -3 -4 -27 7 -54 26 -120 83 -244 118 -390 112 l-97 -5 3 30 c5 38 -5 37
                -39 -6 -15 -19 -32 -35 -37 -35 -30 0 8 56 166 245 97 116 180 219 186 230 6
                11 28 38 49 60 49 52 348 403 739 867 l310 367 -17 29 -17 29 -65 -76 c-49
                -58 -60 -77 -48 -79 23 -5 21 -29 -3 -37 -11 -3 -20 -10 -20 -15 0 -5 -79
                -103 -176 -217 -96 -114 -266 -316 -377 -448 -111 -131 -241 -285 -289 -341
                l-86 -101 -74 -18 c-128 -30 -135 -33 -189 -98 -41 -49 -50 -66 -45 -88 4 -21
                -4 -37 -41 -80 -25 -30 -51 -57 -57 -61 -7 -5 -4 -8 7 -8 9 0 17 -3 16 -7 0
                -5 -45 -55 -100 -113 -68 -71 -93 -93 -79 -67 15 25 20 45 16 63 -8 29 28 90
                72 125 14 11 19 19 11 18 -8 0 -36 -28 -64 -61 -27 -34 -96 -118 -152 -186
                -77 -94 -117 -153 -158 -236 -61 -124 -78 -195 -95 -389 -7 -87 -15 -130 -31
                -162 -12 -23 -78 -110 -148 -191 -519 -612 -550 -655 -555 -750 -1 -25 -38
                -75 -186 -250 -102 -120 -275 -324 -385 -454 -110 -131 -262 -310 -337 -399
                -76 -89 -138 -166 -138 -170 0 -5 14 -21 32 -36 31 -26 36 -27 128 -23 60 3
                106 10 125 20 17 9 61 53 99 99 57 68 71 79 76 64 5 -14 9 -15 14 -6 5 8 29
                11 69 10 71 -3 87 -7 87 -21 0 -7 -26 -8 -72 -4 l-73 7 -132 -153 c-73 -84
                -198 -228 -278 -320 -80 -92 -145 -170 -145 -174 0 -30 211 64 276 124 22 21
                125 136 228 257 103 121 207 243 231 271 l43 50 -29 -4 c-28 -4 -29 -3 -29 42
                0 36 6 52 31 81 35 40 72 46 89 15 8 -16 4 -28 -26 -70 -20 -27 -33 -50 -30
                -50 3 0 56 60 119 133 796 932 797 933 797 940 0 3 -8 8 -17 11 -13 3 -12 4 3
                3 16 -1 63 38 165 139 135 131 240 214 335 262 41 21 314 108 414 133 l55 13
                -80 7 c-99 9 -189 38 -277 90 -99 58 -163 126 -244 257 -3 5 -58 -57 -180
                -204 -147 -177 -395 -464 -401 -464 -4 0 130 161 254 305 24 27 43 58 43 68 0
                11 19 42 42 71 138 168 222 266 228 266 4 0 26 -31 48 -68 53 -88 162 -192
                250 -236 101 -51 180 -69 297 -69 170 1 316 63 435 183 106 107 160 217 181
                368 17 123 -4 230 -71 364 -24 47 -27 58 -15 68 16 13 30 -5 70 -92 32 -70 45
                -146 45 -265 0 -122 -13 -180 -66 -291 -35 -72 -60 -108 -117 -165 -39 -41
                -85 -84 -102 -96 l-30 -22 40 16 40 16 -35 -37 c-47 -50 -169 -105 -315 -142
                -242 -63 -442 -112 -454 -112 -7 0 -9 5 -5 11 4 8 -1 9 -17 4 -44 -14 -78 -35
                -176 -109 -155 -116 -256 -226 -818 -887 -578 -680 -915 -1066 -958 -1101 -58
                -46 -173 -103 -230 -113 -20 -4 -40 -11 -43 -16 -3 -5 -40 -9 -83 -9 -85 0
                -177 25 -236 66 l-35 23 -25 -49 c-13 -27 -28 -73 -31 -102 -6 -50 -4 -55 19
                -70 l24 -17 -19 -57 c-30 -88 -24 -104 50 -140 135 -67 336 -87 487 -49 93 23
                235 91 311 148 81 61 102 85 879 992 368 429 708 819 755 866 152 150 257 201
                605 293 209 55 248 68 351 118 111 54 230 151 314 255 158 199 209 381 211
                753 l1 155 -31 26 -31 26 -83 -18 c-114 -25 -114 -25 -122 19 -4 20 -24 75
                -46 121 -30 65 -36 87 -27 93 41 25 41 24 -5 81 -87 107 -177 255 -177 290 0
                39 60 58 116 37 37 -14 40 -7 16 41 -16 32 -18 47 -11 68 14 37 38 61 55 54 9
                -3 14 2 14 16 0 14 113 155 311 389 171 202 319 373 327 380 9 6 31 12 49 12
                19 0 52 10 73 23 39 22 39 22 87 3 85 -33 130 -32 193 6 30 18 67 51 82 73 37
                53 58 139 48 193 -4 27 -2 63 6 99 20 87 1 167 -54 225 l-26 28 -22 -21 c-22
                -21 -24 -21 -50 -5 -14 10 -51 20 -82 23 -33 4 -60 12 -65 20 -7 11 -31 14
                -110 12 -56 -2 -110 -5 -119 -7z m-1104 -1883 c100 -30 172 -73 253 -155 113
                -114 164 -240 164 -403 -1 -105 -20 -184 -68 -272 -88 -163 -247 -274 -429
                -298 -300 -42 -576 157 -640 459 -20 94 -13 195 20 293 13 40 23 74 21 76 -6
                5 -33 -65 -40 -102 -9 -47 -21 -65 -35 -51 -16 16 2 77 48 173 39 80 163 221
                196 221 9 0 16 -2 16 -5 0 -3 -30 -36 -67 -74 -38 -38 -79 -88 -92 -112 -28
                -52 -23 -49 16 8 42 61 97 119 150 157 61 43 182 96 242 106 67 10 166 2 245
                -21z"
          />
          <path d="M2980 4254 c-155 -41 -291 -150 -355 -285 -98 -207 -58 -438 103
          -599 93 -93 186 -137 308 -143 l51 -3 -3 33 c-1 20 2 33 9 34 7 0 -21 7 -61
          14 -144 26 -265 113 -323 230 -47 96 -49 139 -8 216 80 150 229 239 401 239
          171 0 326 -99 399 -255 l31 -64 -17 -58 c-31 -109 -83 -176 -187 -244 -34 -21
          -56 -39 -50 -39 7 0 9 -13 5 -36 -6 -36 -6 -37 22 -30 83 21 200 125 252 223
          46 86 64 161 64 262 0 150 -57 280 -168 381 -128 117 -315 166 -473 124z"/>
          <path d="M4582 6080 c-40 -25 -55 -61 -49 -120 13 -113 160 -140 212 -39 16
          31 18 119 3 119 -5 0 -7 4 -3 10 8 13 -68 50 -102 50 -15 0 -43 -9 -61 -20z"/>
          <path d="M335 1058 c-99 -116 -184 -217 -188 -224 -5 -9 6 -22 35 -40 53 -34
          113 -52 192 -60 l61 -5 90 108 c50 59 146 173 214 252 68 79 116 141 105 137
          -101 -42 -254 -47 -295 -10 -12 10 -24 27 -28 36 -5 14 -47 -30 -186 -194z"/>
        </g>
      </svg>
    </div>
  );
}

export default Monado;
